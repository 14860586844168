<template>
  <div class="wrap">
    <div class="top_bg">
      <div class="content_wrap">
        <div class="times">
          {{ `Số lần rút thăm: ${userInfo.gift_times || 0} lần` }}
        </div>
        <div class="lottery" @click="handleSubmit">Bóc thăm ngay</div>
      </div>
    </div>
    <div class="bottom_bg">
      <div class="integral_bar">
        <div class="integral_text">Điểm số của tôi</div>
        <div class="integral_times">{{ userInfo.point }}</div>
      </div>
      <div class="padding">
        <van-grid square :column-num="3" :gutter="10" icon-size="30px">
          <van-grid-item
            :icon="item.icon"
            :text="item.text"
            v-for="(item, index) in tools"
            :key="index"
            @click="item.fun"
          ></van-grid-item>
        </van-grid>
        <div class="marquee_wrap">
          <van-icon
            class="icon"
            :color="verConfig.noticeIconColor || '#fcb000'"
            name="volume"
            size="28"
          />
          <marquee
            behavior="scroll"
            direction="up"
            class="marquee"
            scrollamount="3"
          >
            <div
              v-for="(item, index) in blindNotice"
              class="halloffame"
              :key="index"
            >
              <div class="notice-item-wrap">
                <div>
                  <span>
                    Chúc mừng khách hàng
                    <span class="spancolor"> {{ item.name }} </span>&nbsp;trúng
                    thưởng&nbsp;<span class="spancolor">
                      {{ item.prize }}
                    </span>
                  </span>
                </div>
              </div>
            </div>
          </marquee>
        </div>
        <!-- 奖品列表 -->
        <template>
          <div class="prize_title">Danh sách trúng thưởng</div>
          <ListItem
            v-for="(item, index) in prizeArr"
            :key="`prize_${index}`"
            :data="item"
            type="PRIZE"
          />
        </template>
        <!-- 兑奖列表 -->
        <template>
          <div class="point_title">Danh sách đổi điểm thưởng</div>
          <ListItem
            v-for="(item, index) in redemptionArr"
            :key="`point_${index}`"
            :data="item"
            type="POINT"
          />
        </template>
      </div>
    </div>
    <div class="overlay" @click.stop="showGiftInfo = false" v-if="showGiftInfo">
      <div class="content" @click.stop="() => {}">
        <img
          :src="
            $img(
              tempGiftInfo.item.picture[0]
                ? tempGiftInfo.item.picture[0].path
                : ''
            )
          "
          alt=""
        />
        <div class="text">{{ tempGiftInfo.title || {} }}</div>
        <div class="btn_confirm" @click.stop="showGiftInfo = false">
          xác nhận
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { isLinkGame, isGameGoLink, $get, $post } from "@/utils/request.js";
import { userinfo } from "@/api/use.js";
import { Dialog, Toast } from "vant";
import ListItem from "./components/list_item.vue";
import { mapGetters } from "vuex";
export default {
  components: { ListItem },
  data() {
    this.tools = [
      {
        icon: require("./images/icon_rule.png"),
        text: "Luật xổ số",
        fun: () => {
          Dialog.alert({ message: this.rule, confirmButtonText: "Chắc chắn" });
        },
      },
      {
        icon: require("./images/icon_service.png"),
        text: "Dịch vụ trực tuyến",
        fun: () => {
          location.href = this.serviceURL;
        },
      },
      {
        icon: require("./images/icon_record.png"),
        text: "Lịch sử trúng thưởng",
        fun: () => {
          this.$router.push("/WinningRecord");
        },
      },
    ];
    return {
      userInfo: {},
      serviceURL: "",
      prizeArr: [],
      redemptionArr: [],
      rule: "",
      showGiftInfo: false,
      tempGiftInfo: {
        // title: "测试",
        // item: {
        //   picture: [
        //     {
        //       path: "uploads/picture/202101/a2f31404cf255833712852c6577245ac.png",
        //     },
        //   ],
        // },
      },
      isShake: false, // 加载flag
    };
  },
  computed: {
    ...mapGetters(["blindNotice"]),
  },
  created() {
    this.getUserInfo();
    this.getService();
    this.getRecords();
  },
  methods: {
    refresh() {
      this.getRecords();
      this.getUserInfo();
    },
    async getUserInfo() {
      try {
        const { data } = await userinfo();
        const res = await this.verifyRes(data);
        this.userInfo = res;
      } catch (error) {
        console.error("获取用户信息失败:", error);
      }
    },
    async getService() {
      const {
        data: { data, ret },
      } = await $get({
        url: "other/config",
        params: {
          act: "system",
        },
      });
      if (ret === 1) {
        this.serviceURL = data.service_url;
        localStorage.setItem("appurl_android", data.appurl_android);
      }
    },
    verifyRes({ ret, msg, data }) {
      return new Promise((resolve, reject) => {
        if (ret == -1) {
          return this.$router.replace("/login");
        }
        if (ret == 1) {
          resolve(data);
          return;
        }
        Toast(msg);
      });
    },
    delay() {
      return new Promise((resolve, reject) => {
        setTimeout(() => {
          resolve();
        }, 3000);
      });
    },
    async handleSubmit() {
      if (this.isShake) return;
      Toast.loading({ duration: 0 });
      this.isShake = true;
      try {
        const [delayRes, { data }] = await Promise.all([
          this.delay(),
          $post("/marketing/gift", {
            act: "user_gift",
            type: "point",
          }),
        ]);
        this.isShake = false;
        Toast.clear();
        this.verifyRes(data).then((res) => {
          this.tempGiftInfo = res || {};
          this.showGiftInfo = true;
          this.refresh();
        });
      } catch (error) {
        this.isShake = false;
        Toast.clear();
        console.error("抽奖错误:", error);
      }
    },
    async getRecords() {
      this.$store.dispatch("getGiftConfig", "test");
      try {
        const { data } = await $get("/marketing/gift", {
          act: "list",
          type: "point",
        });
        this.verifyRes(data).then(({ setting: res }) => {
          this.prizeArr = res.odds || [];
          this.redemptionArr = res.goods || [];
          this.rule = res.rule || "";
        });
      } catch (error) {
        console.error("获取中奖列表错误:", error);
      }
    },
  },
};
</script>

<style lang="less" scoped>
.wrap {
  font-size: 32px;
  @top_height: calc(100vw / 750 * 800);
  .top_bg {
    width: 100vw;
    height: @top_height;
    background-image: url("./images/top_bg.png");
    position: relative;
    background-size: 100% 100%;
    .content_wrap {
      position: absolute;
      width: 100vw;
      height: 190px;
      bottom: 20px;
      .times {
        font-size: 38px;
        color: #ffc600;
        text-align: center;
        padding-top: 10px;
      }
      .lottery {
        @height: calc(100vw / 750 * 137);
        width: calc(100vw / 750 * 400);
        height: @height;
        line-height: calc(@height - 20px);
        background-size: 100% 100%;
        background-image: url("./images/btn_bg.png");
        margin: 0 auto 0;
        text-align: center;
        color: #f00;
        font-size: 42px;
        &:active {
          transform: translateY(6px);
        }
      }
    }
  }
  .bottom_bg {
    min-height: calc(100vh - @top_height);
    background-size: 100% auto;
    background-image: url("./images/bottom_bg.png");
    padding-bottom: 20px;
    overflow: hidden;
    .integral_bar {
      @height: 150px;
      width: 60vw;
      height: @height;
      background-image: url("./images/integral.png");
      color: #fff;
      font-size: calc(100vw / 1080 * 54);
      background-size: 100% 100%;
      margin: 30px auto 0;
      display: flex;
      flex-direction: column;
      align-items: center;
      justify-content: center;
    }
    .padding {
      margin: 0 30px;
      /deep/ .van-grid-item__content {
        background: transparent;
        &::after {
          border: none;
        }
      }
      /deep/ .van-grid-item__text {
        white-space: nowrap;
        color: #fcb000;
      }
      .prize_title {
        color: #fff;
        font-size: 36px;
      }
      .point_title {
        .prize_title();
        margin-top: 40px;
      }
    }
  }
  .overlay {
    width: 100vw;
    height: 100vh;
    background: rgba(0, 0, 0, 0.6);
    position: fixed;
    top: 0;
    left: 0;
    overflow: hidden;
    @padding: 20px;
    .content {
      background-image: url("./images/success_bg.png");
      background-size: 100% 100%;
      @top: 687px;
      padding: @top 140px 0 170px;
      height: 1180px - @top; // 总高1180px
      display: flex;
      flex-direction: column;
      align-items: center;
      position: relative;
      .text {
        font-size: 32px;
        color: #fff;
        margin-top: 30px;
      }
      > img {
        @size: 132px * 2;
        width: @size;
        height: @size;
        background-color: #ffffff;
        border-radius: 20px;
        margin-top: 30px;
      }
      .btn_confirm {
        position: absolute;
        padding: 30px 80px;
        // background: #71D249;
        background-image: url("./images/success_btn_bg.png");
        background-size: 100% 100%;
        bottom: -60px;
        border-radius: 20px;
        color: #fff;
        box-shadow: 0px 4px 6px 0px rgba(0, 46, 3, 0.85);
      }
    }
  }
}

.marquee_wrap {
  display: flex;
  // background-color: #fff;
  padding: 12px 4vw 12px 4vw;
  box-shadow: 0 0.533vw 1.333vw 0 rgba(255, 255, 255, 0.1);
  align-items: center;
  background-image: url("./images/list_item.png");
  background-size: 100% 100%;
  margin-bottom: 36px;
  color: #fff;
  border: 1px solid #fff;
  border-radius: 20px;
  .spancolor{
    color: #fcb000;
  }
  .icon {
    font-size: 36px;
    margin-right: 28px;
  }
}

// 轮播
.marquee {
  // width: 85%;
  // margin: 0 auto !important;
  // margin-top: 20px;
  // height: calc(34rem / 16);
  height: 3em;
  // margin-left: 5vw;
  border-radius: 1vw;

  .halloffame {
    // padding: 0 9vw;
    font-size: 12px;
    line-height: 3em;
    margin-top: 2em;
  }
}
</style>
